import React, { createContext, useEffect, useState } from "react";
import Layout from "../layout/layout";
import Login from "../pages/login";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [Theme, setTheme] = useState("theme-4");
  const [ThemeColor, setThemeColor] = useState("theme-color-1");
  const [MiniSidebar, setMiniSidebar] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [questionYubObj, setQuestionYubObj] = useState();
  const [imageCoordinate, setImageCoordinate] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState({});

  
  useEffect(()=>{
    /* let thm = localStorage.getItem('theme') || "theme-1";
    let thmClr = localStorage.getItem('themeColor'); */
    let authLocal = localStorage.getItem('token');
    /* setTheme(thm);
    setThemeColor(thmClr); */
    setAuth(authLocal);
  },[])
  return (
    <UserContext.Provider
        value={{
        ThemeColor,
        Theme,
        auth,
        MiniSidebar,
        setThemeColor,
        setTheme,
        setAuth,
        setMiniSidebar,
        userInfo, setUserInfo,
        questionYubObj, setQuestionYubObj,
        imageCoordinate, setImageCoordinate,
        breadcrumbs, setBreadcrumbs
      }}
    >
      {children}
    </UserContext.Provider>
  );
};