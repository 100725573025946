// import logo from './logo.svg';
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "react-datepicker/dist/react-datepicker.css";


import React from 'react';

import { UserContext, UserProvider } from "./context/theme";
import Layout from "./layout/layout";
import Login from "./pages/login";

function App() {
  return (
    <>
      <UserProvider>
        <UserContext.Consumer>
          {({auth}) => (
            auth ? <Layout/> : <Login/>
          )}
        </UserContext.Consumer>
      </UserProvider>
    </>
  );
}

export default App;
