import React, { useContext, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";
import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
// import { DropDown, Menu } from '../components/common/sidebarMenu';
import { UserContext } from "../context/theme";
import ROOT_URL from "../services/api-url";
import authAxios from "../services/authAxios";
import common from "../services/common";

function Header() {
  const { setAuth, setUserInfo } = useContext(UserContext);
  const{userInfo}=useContext(UserContext);
  // const [isActive, setActive] = useState('false');

  // const ToggleClass = (e) =>{
  //     e.preventDefault();
  //     setActive(!isActive);
  // }
  const Logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };

  const getProfile = async (id) => {
    await authAxios({
      method: "GET",
      url: `${ROOT_URL}/admins/get/${id}`,
    })
      .then((res) => {
        setUserInfo(res.data.data);
        // navigate('/admin')
      })
      .catch((error) => {
        common.error(error);
      });
  };

  useEffect(() => {
    let userId = reactLocalStorage.get("userId");
    if (userId) {
      getProfile(userId);
    }
  }, []);
  return (
    <>
      <div className="left-sidebar-wrapper">
        <nav className="sidebar">
          <div className="sidebar-header text-center">
            <NavLink activeclassname="isActive" to="/">
              <img
                src={`/assets/images/logo-white.png`}
                className="img-fluid"
                alt="logo"
              />
            </NavLink>

            <strong>
              <NavLink activeclassname="isActive" to="/">
                <img src={`/assets/images/logo-small.png`} alt="logo" />
              </NavLink>
            </strong>
          </div>

          
          <div className="left-nav-menu">

           {(userInfo.role==="MODULE_MANAGER"?        //MODULE_MANAGER
          ( <DropDownMenu>
                
                <Dropdown icon="fas fa-graduation-cap" title="Exam">
                    <Menu path={`/create-exam`} title="Create Exam" icon="fas fa-plus" />
                    <Menu  path={`/exam`} title="Exam List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-question-circle" title="Question">
                    <Menu path={`/create-question`} title="Create Question" icon="fas fa-plus" />
                    <Menu  path={`/question`} title="Question List" icon="fas fa-list" />
                </Dropdown>
               
                <Dropdown icon="fas fa-cubes" title="Package">
                    <Menu path={`/create-package`} title="Create Package" icon="fas fa-plus" />
                    <Menu  path={`/packages`} title="Package List" icon="fas fa-list" />
                </Dropdown>
               
                <Menu  path={`/test`} title="Test List" icon="fas fa-user-graduate" />
            </DropDownMenu>)

            :(userInfo.role==="ACCOUNT_MANAGER"?              //ACCOUNT_MANAGER
           ( <DropDownMenu>
                <Menu path="/" title="Dashboard" icon="icon-wrap icon-home" />
            </DropDownMenu>)

            :(userInfo.role==="CONTENT_MANAGER"?                 //Content-Manager      
            <DropDownMenu>                                      
            <Dropdown icon="fas fa-rss-square" title="Blogs">
                <Menu
                  path={`/create-blog`}
                  title="Create Blog"
                  icon="fas fa-plus"
                />
                <Menu path={`/blog`} title="Blog List" icon="fas fa-list" />
              </Dropdown>
            </DropDownMenu> 
            :                                           //ADMIN
            (<DropDownMenu>
                <Menu path="/" title="Dashboard" icon="icon-wrap icon-home" />
                <Dropdown icon="icon-wrap icon-user" title="Admin">
                    <Menu path={`/create-admin`} title="Create Admin" icon="fas fa-plus" />
                    <Menu  path={`/admin`} title="Admin List" icon="fas fa-list" />
                </Dropdown>
                <Menu path="/user" title="Students" icon="icon-wrap icon-users" />
                <Menu  path={`/orders`} title="Orders" icon="fas fa-shopping-cart" />
                <Dropdown icon="fas fa-sitemap" title="Category">
                    <Menu path={`/add-category`} title="Add Category" icon="fas fa-plus" />
                    <Menu  path={`/category`} title="Category List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fa fa-list-ul" title="Sub Category">
                    <Menu path={`/add-sub-category`} title="Add Sub Category" icon="fas fa-plus" />
                    <Menu  path={`/sub-category`} title="Sub Category List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-tag" title="Tags">
                    <Menu path={`/create-tag`} title="Create Tags" icon="fas fa-plus" />
                    <Menu  path={`/tags`} title="Tags List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-question-circle" title="Question">
                    <Menu path={`/create-question`} title="Create Question" icon="fas fa-plus" />
                    <Menu  path={`/question`} title="Question List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-keyboard" title="Case Study">
                    <Menu path={`/create-case-study`} title="Create Case Study" icon="fas fa-plus" />
                    <Menu  path={`/case-study`} title="Case Study List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-graduation-cap" title="Exam">
                    <Menu path={`/create-exam`} title="Create Exam" icon="fas fa-plus" />
                    <Menu  path={`/exam`} title="Exam List" icon="fas fa-list" />
                </Dropdown>
                <Dropdown icon="fas fa-cubes" title="Package">
                    <Menu path={`/create-package`} title="Create Package" icon="fas fa-plus" />
                    <Menu  path={`/packages`} title="Package List" icon="fas fa-list" />
                </Dropdown>
                <Menu  path={`/test`} title="Test List" icon="fas fa-user-graduate" />
                <Dropdown icon="fas fa-percent" title="Coupons">
                  <Menu path={`/create-coupon`} title="Create Coupon" icon="fas fa-plus" />
                  <Menu  path={`/coupon`} title="Coupons List" icon="fas fa-list" />
                </Dropdown>
                <Menu  path={`/reviews`} title="Reviews" icon="fas fa-star" />
                <Menu  path={`/comments`} title="Comments" icon="fas fa-comments" />
                <Menu  path={`/helpdesk`} title="Help" icon="fas fa-headset" />
                <Menu  path={`/contact`} title="Contact Us" icon="far fa-envelope-open" />
                <Menu  path={`/newsletter`} title="Newsletter" icon="far fa-envelope" />
                <Menu  path={`/report-questions`} title="Report QSN" icon="fas fa-flag" />
                <Dropdown icon="fas fa-info-circle" title="Informations">
                    <Menu  path={`/homepage`} title="Home" icon="far fa-newspaper" />
                    <Menu path={`/about`} title="About Us" icon="far fa-newspaper" />
                    <Menu path={`/terms`} title="Terms and Conditions" icon="far fa-newspaper" />
                    <Menu  path={`/privacy`} title="Privacy Policy" icon="far fa-newspaper" />
                    <Menu  path={`/footer`} title="Footer" icon="far fa-newspaper" />
                </Dropdown>
                <Dropdown icon="fas fa-info-circle" title="Faqs">
                  <Menu path={`/create-faqs`} title="Create Faqs" icon="fas fa-plus" />
                  <Menu  path={`/faqs`} title="Faqs List" icon="fas fa-list" />
                </Dropdown>
                
                <Dropdown icon="fas fa-rss-square" title="Blogs">
                  <Menu path={`/create-blog`} title="Create Blog" icon="fas fa-plus" />
                  <Menu path={`/blog`} title="Blog List" icon="fas fa-list" />
                </Dropdown>
            </DropDownMenu>)
            
            )) )}
             
          </div>
          <div className="version">
            <span className="vs-mobile">{common.version()}</span>
            <span className="vs-web">{common.version()}</span>
          </div>
         
        </nav>
      </div>
    </>
  );
}
export default Header;
