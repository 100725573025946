import { useFormik } from 'formik';
import React, { useContext } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { UserContext } from '../../context/theme';
import * as Yup from "yup";
import axios from 'axios';
import { toast } from 'react-toastify';
import ROOT_URL from '../../services/api-url';
import { reactLocalStorage } from 'reactjs-localstorage';
import common from '../../services/common';



const LoginForm = () => {
    const context = useContext(UserContext)
    /* const Login = () => {
        localStorage.setItem("token","adfasfasdfsdaf");
        context.setAuth(true);
    } */

    const initialValues = {
        email: "",
        password: "",
      };
      const validationSchema = Yup.object({
        email:Yup.string()
            .email('Please enter valid email')
            .required('Please enter email'),
        password: Yup.string().required("Please enter password"),
      });
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
          // console.log("submit", values);
          login(values);
        },
      });

      const login = (userData) => {
        axios
          .post(`${ROOT_URL}/login`, userData)
          .then((res) => {
            if(res.data != undefined){
              reactLocalStorage.set("token", res.data.token);
              reactLocalStorage.set("userId", res.data._id);
              reactLocalStorage.set("role", res.data.role);
              context.setAuth(true);
              toast.success(res.data.message);
              
              
            }else{
              toast.error(res.data.message);
            }
          }).catch((error) => {
            common.error(error);
          });
          
      };






    return (
        <div className="wrapLogin__form">
            <div className='centerWidgetLogo'>
                <img src={`assets/images/logo.png`} alt="Logo"/>
            </div>
            <div className='Login__box_wrap'>
                <h2>Welcome Back</h2>
                <p>Enter your credentials to access your account</p>

                <div className='wrap_log__form mt-4'>
                    <form onSubmit={formik.handleSubmit} className='login_form'>
                        {/* Email Field */}
                        <div className='field__item mb-2'>
                            <label>E-mail Address</label>
                            <div className='field__wrap'>
                                <span className='icon-mail icon-in-start field_log_icon'></span>
                                <input type="email"  {...formik.getFieldProps("email")} placeholder='Enter Your Email' />
                                <span className='icon-mail icon-in-end field_log_icon'></span>
                            </div>
                            {/* for validation text */}
                            {formik.touched.email && formik.errors.email ? (
                                <div className='field__validate d-none'>
                                    <small className='form-text'>{formik.errors.email}</small>
                                </div>
                            ) : null}
                            
                        </div>
                        {/* Password Field */}
                        <div className='field__item mb-3 pt-1'>
                            <label>Password</label>
                            <div className='field__wrap'>
                                <span className='icon-lock icon-in-start field_log_icon'></span>
                                <input type="password"  {...formik.getFieldProps("password")} placeholder='Enter Password' />
                                <span className='icon-lock icon-in-end field_log_icon'></span>
                            </div>
                            {/* for validation text */}
                            {formik.touched.password && formik.errors.password ? (
                                <div className='field__validate d-none'>
                                    <small className='form-text'>{formik.errors.password}</small>
                                </div>
                            ) : null}
                        </div>
                        {/* Remember and Forgot */}
                        <div className='row custom_margin'>
                            {/* Remember me */}
                            <div className='col'>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input" id='remember'/>
                                    <label className="form-check-label" htmlFor="remember">Remember Me</label>
                                </div>
                            </div>
                            {/* Forgot password */}
                            {/* <div className='col text-end'>
                                <Link to="#" className='text-dark'>Forgot password?</Link>
                            </div> */}
                        </div>

                        <button type='submit' className='mt-4 btn-theme btn-custom'>Submit</button>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default LoginForm
