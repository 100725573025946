import React, { Suspense, useContext, useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import { Routes, Route } from "react-router-dom";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../components/loader/loader";

const Home = React.lazy(() => import("../pages/home"));
const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminCreate = React.lazy(() => import("../pages/admin/add"));
const AdminEdit = React.lazy(() => import("../pages/admin/edit"));
const ChangesPassword = React.lazy(() =>
  import("../pages/settings/changePassword")
);
const Settings = React.lazy(() => import("../pages/settings/settings"));
const ChangesPasswordAdmin = React.lazy(() =>
  import("../pages/admin/adminChangePassword")
);
const CategoryList = React.lazy(() => import("../pages/category/list"));
const CategoryAdd = React.lazy(() => import("../pages/category/add"));
const CategoryEdit = React.lazy(() => import("../pages/category/edit"));
const SubCategoryList = React.lazy(() => import("../pages/subCategory/list"));
const SubCategoryAdd = React.lazy(() => import("../pages/subCategory/add"));
const SubCategoryEdit = React.lazy(() => import("../pages/subCategory/edit"));
const ExamList = React.lazy(() => import("../pages/exam/list"));
const ExamAdd = React.lazy(() => import("../pages/exam/add"));
const ExamEdit = React.lazy(() => import("../pages/exam/edit"));
const QuestionList = React.lazy(() => import("../pages/questions/list"));
const QuestionAdd = React.lazy(() => import("../pages/questions/add"));
const QuestionEdit = React.lazy(() => import("../pages/questions/edit"));
const TagsList = React.lazy(() => import("../pages/tags/list"));
const TagsAdd = React.lazy(() => import("../pages/tags/add"));
const TagsEdit = React.lazy(() => import("../pages/tags/edit"));
const UserList = React.lazy(() => import("../pages/user/list"));
const UserView = React.lazy(() => import("../pages/user/view"));
const PackageCreate = React.lazy(() => import("../pages/package/add"));
const PackageList = React.lazy(() => import("../pages/package/list"));
const PackageEdit = React.lazy(() => import("../pages/package/edit"));
const CaseStudyCreate = React.lazy(() => import("../pages/caseStudy/add"));
const CaseStudyList = React.lazy(() => import("../pages/caseStudy/list"));
const CaseStudyEdit = React.lazy(() => import("../pages/caseStudy/edit"));
const TestCreate = React.lazy(() => import("../pages/test/add"));
const TestList = React.lazy(() => import("../pages/test/list"));
const TestEdit = React.lazy(() => import("../pages/test/edit"));
const BlogCreate = React.lazy(() => import("../pages/blog/add"));
const CouponList = React.lazy(() => import("../pages/coupons/list"));
const CouponCreate = React.lazy(() => import("../pages/coupons/add"));
const CouponEdit = React.lazy(() => import("../pages/coupons/edit"));
const BlogList = React.lazy(() => import("../pages/blog/list"));
const BlogEdit = React.lazy(() => import("../pages/blog/edit"));
const HelpDeskList = React.lazy(() => import("../pages/helpDesk/list"));
const HelpDeskChat = React.lazy(() => import("../pages/helpDesk/chat"));
const HomePageContent = React.lazy(() => import("../pages/information/home"));
const ContactUs = React.lazy(() => import("../pages/contact/list"));
const Newsletter = React.lazy(() => import("../pages/newsletter/list"));
const TermsCondition = React.lazy(() => import("../pages/information/terms"));
const PrivacyPolicy = React.lazy(() => import("../pages/information/privacy"));
const AboutUs = React.lazy(() => import("../pages/information/about"));
const Comments = React.lazy(() => import("../pages/comments/list"));
const CommentsView = React.lazy(() => import("../pages/comments/moderate"));
const FaqsList = React.lazy(() => import("../pages/faqs/list"));
const FaqsAdd = React.lazy(() => import("../pages/faqs/add"));
const FaqsEdit = React.lazy(() => import("../pages/faqs/edit"));
const UserFooter = React.lazy(() => import("../pages/information/footer"));
const Review = React.lazy(() => import("../pages/reviews/list"));
const ReviewView = React.lazy(() => import("../pages/reviews/moderate"));
const QsnReport = React.lazy(() => import("../pages/qsnReport/list"));
const OrderList = React.lazy(() => import("../pages/orders/list"));
const OrdersView = React.lazy(() => import("../pages/orders/view"));
function Layout() {
  const context = useContext(UserContext);
  const { userInfo } = useContext(UserContext);

  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/admins/token/refresh`,
    })
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
      })
      .catch((error) => {
        // reactLocalStorage.remove("token");
      });
  }
  useEffect(() => {
    let token = reactLocalStorage.get("token");
    const intrVal = setInterval(() => {
      refreshtoken();
    }, 10000);
    if (!token) {
      clearInterval(intrVal);
    }
  }, []);

  return (
    <div
      className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
    >
      <ToastContainer />
      <Header />
      <div className="all-content-wrapper">
        <TopNavbar />
        <Loader />
        <Suspense fallback={"loading"}>
          {/* MODULE_MANAGER */}

          {userInfo.role == "MODULE_MANAGER" ? (
            <Routes>
              <Route path="/exam" element={<ExamList />} />
              <Route path="/create-exam" element={<ExamAdd />} />
              <Route path="/exam/:id" element={<ExamEdit />} />
              <Route path="/question" element={<QuestionList />} />
              <Route path="/create-question" element={<QuestionAdd />} />
              <Route
                path="/create-question/:case_id/:caseId"
                element={<QuestionAdd />}
              />
              <Route path="/question/:id" element={<QuestionEdit />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/create-package" element={<PackageCreate />} />
              <Route path="/packages" element={<PackageList />} />
              <Route path="/edit-package/:id" element={<PackageEdit />} />
              <Route path="/create-test/:examId" element={<TestCreate />} />
              <Route path="/test" element={<TestList />} />
              <Route path="/edit-test/:id" element={<TestEdit />} />
            </Routes>
          ) : userInfo.role == "ACCOUNT_MANAGER" ? ( //Account_Manager
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
            </Routes>
          ) : userInfo.role === "CONTENT_MANAGER" ? ( //Content_Manager
            <Routes>
              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />
              <Route path="/edit-category/:id" element={<CategoryEdit />} />
            </Routes>
          ) : (
            <Routes>
              {" "}
              {/* Admin */}
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/admin" element={<AdminList />} />
              <Route path="/user" element={<UserList />} />
              <Route path="/user-view/:id" element={<UserView />} />
              <Route path="/create-admin" element={<AdminCreate />} />
              <Route path="/edit-admin/:id" element={<AdminEdit />} />
              <Route path="/change-password" element={<ChangesPassword />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/change-password/:id"
                element={<ChangesPasswordAdmin />}
              />
              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />
              <Route path="/edit-category/:id" element={<CategoryEdit />} />
              <Route path="/sub-category" element={<SubCategoryList />} />
              <Route path="/add-sub-category" element={<SubCategoryAdd />} />
              <Route
                path="/edit-sub-category/:id"
                element={<SubCategoryEdit />}
              />
              <Route path="/exam" element={<ExamList />} />
              <Route path="/create-exam" element={<ExamAdd />} />
              <Route path="/exam/:id" element={<ExamEdit />} />
              <Route path="/question" element={<QuestionList />} />
              <Route path="/create-question" element={<QuestionAdd />} />
              <Route
                path="/create-question/:case_id/:caseId"
                element={<QuestionAdd />}
              />
              <Route path="/question/:id" element={<QuestionEdit />} />
              <Route path="/tags" element={<TagsList />} />
              <Route path="/create-tag" element={<TagsAdd />} />
              <Route path="/tags/:id" element={<TagsEdit />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/create-package" element={<PackageCreate />} />
              <Route path="/packages" element={<PackageList />} />
              <Route path="/edit-package/:id" element={<PackageEdit />} />
              <Route path="/create-case-study" element={<CaseStudyCreate />} />
              <Route path="/case-study" element={<CaseStudyList />} />
              <Route path="/edit-case-study/:id" element={<CaseStudyEdit />} />
              <Route path="/create-test/:examId" element={<TestCreate />} />
              <Route path="/test" element={<TestList />} />
              <Route path="/edit-test/:id" element={<TestEdit />} />
              <Route path="/create-blog" element={<BlogCreate />} />
              <Route path="/coupon" element={<CouponList />} />
              <Route path="/create-coupon" element={<CouponCreate />} />
              <Route path="/edit-coupon/:id" element={<CouponEdit />} />
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:id" element={<BlogEdit />} />
              <Route path="/helpdesk" element={<HelpDeskList />} />
              <Route path="/helpdesk/chat/:id" element={<HelpDeskChat />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsCondition />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/homepage" element={<HomePageContent />} />
              <Route path="/create-faqs" element={<FaqsAdd />} />
              <Route path="/faqs/" element={<FaqsList />} />
              <Route path="/faqs/:id" element={<FaqsEdit />} />
              <Route path="/comments" element={<Comments />} />
              <Route path="/comment-view/:id" element={<CommentsView />} />
              <Route path="/footer" element={<UserFooter />} />
              <Route path="/reviews" element={<Review />} />
              <Route path="/review-view/:id" element={<ReviewView />} />
              <Route path="/orders" element={<OrderList />} />
              <Route path="/order-view/:id" element={<OrdersView />} />
              <Route path="/report-questions" element={<QsnReport />} />
            </Routes>
          )}
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
