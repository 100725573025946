import React from 'react'

const LeftWidget = () => {
    return (
        
        <div className='log_LeftWidget_wrap'>
        <div className='logoContain'>
            <img src={`assets/images/logo.png`} alt='Logo' className='side__logo' />
        </div>
        <div className='secondaryImg'>
            {/* <img src={LoginDashboardImg} alt='dashboard' /> */}
            <svg  viewBox="0 0 1440 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M681.725 839.623C879.807 839.623 1040.38 679.046 1040.38 480.963C1040.38 282.881 879.807 122.304 681.725 122.304C483.643 122.304 323.065 282.881 323.065 480.963C323.065 679.046 483.643 839.623 681.725 839.623Z"
                        stroke="#D1E8F7" strokeWidth="1.91649" strokeMiterlimit="10" />
                    <path
                        d="M389.948 283.002C396.925 283.002 402.581 277.346 402.581 270.369C402.581 263.392 396.925 257.737 389.948 257.737C382.972 257.737 377.316 263.392 377.316 270.369C377.316 277.346 382.972 283.002 389.948 283.002Z"
                        fill="#FEBB94" />
                    <path
                        d="M1040.38 507.255C1047.36 507.255 1053.02 501.6 1053.02 494.623C1053.02 487.646 1047.36 481.99 1040.38 481.99C1033.41 481.99 1027.75 487.646 1027.75 494.623C1027.75 501.6 1033.41 507.255 1040.38 507.255Z"
                        className="ele_themeBg_color" />
                    <path
                        d="M696.436 852.255C703.412 852.255 709.068 846.599 709.068 839.623C709.068 832.646 703.412 826.99 696.436 826.99C689.459 826.99 683.803 832.646 683.803 839.623C683.803 846.599 689.459 852.255 696.436 852.255Z"
                         className="ele_themeBg_color" />
                    <path
                        d="M690.034 750.876C837.798 750.876 957.584 631.09 957.584 483.326C957.584 335.561 837.798 215.775 690.034 215.775C542.269 215.775 422.483 335.561 422.483 483.326C422.483 631.09 542.269 750.876 690.034 750.876Z"
                        stroke="#D1E8F7" strokeWidth="1.42966" strokeMiterlimit="10" />
                    <path
                        d="M952.998 546.643C958.202 546.643 962.421 542.424 962.421 537.22C962.421 532.015 958.202 527.796 952.998 527.796C947.793 527.796 943.574 532.015 943.574 537.22C943.574 542.424 947.793 546.643 952.998 546.643Z"
                        fill="#FEBB94" />
                    <path
                        d="M441.885 593.296C447.09 593.296 451.309 589.077 451.309 583.872C451.309 578.668 447.09 574.449 441.885 574.449C436.681 574.449 432.462 578.668 432.462 583.872C432.462 589.077 436.681 593.296 441.885 593.296Z"
                        className="ele_themeBg_color" />
                    <path
                        d="M570.189 253.289C575.394 253.289 579.613 249.07 579.613 243.865C579.613 238.661 575.394 234.442 570.189 234.442C564.985 234.442 560.766 238.661 560.766 243.865C560.766 249.07 564.985 253.289 570.189 253.289Z"
                         className="ele_themeBg_color" />
                    <path
                        d="M829.284 779.603H520.305C506.9 779.603 497.078 768.736 498.367 755.331L551.112 206.636C552.4 193.231 564.311 182.364 577.716 182.364H886.695C900.1 182.364 909.922 193.231 908.633 206.636L855.888 755.331C854.6 768.736 842.688 779.603 829.284 779.603Z"
                         className="ele_themeBg_color" />
                    <path
                        d="M807.508 779.603H498.529C485.125 779.603 475.303 768.736 476.591 755.331L529.336 206.636C530.625 193.231 542.536 182.364 555.941 182.364H864.92C878.324 182.364 888.146 193.231 886.858 206.636L834.113 755.331C832.824 768.736 820.913 779.603 807.508 779.603Z"
                        fill="url(#paint0_linear)" />
                    <path
                        d="M795.799 761.362H513.746C501.323 761.362 492.22 751.292 493.414 738.869L542.994 223.098C544.189 210.675 555.227 200.605 567.65 200.605H849.703C862.126 200.605 871.229 210.675 870.035 223.098L820.455 738.869C819.26 751.292 808.222 761.362 795.799 761.362Z"
                        fill="white" />
                    <path
                        d="M818.418 435.237L515.847 434.968C514.611 435.002 513.382 434.771 512.242 434.292C511.103 433.813 510.078 433.096 509.238 432.189C508.397 431.283 507.76 430.207 507.368 429.035C506.976 427.862 506.839 426.619 506.966 425.389L523.278 244.26C523.573 241.646 524.815 239.231 526.769 237.47C528.722 235.708 531.252 234.723 533.882 234.698L836.453 234.967C837.689 234.934 838.918 235.164 840.058 235.643C841.197 236.123 842.222 236.839 843.062 237.746C843.903 238.653 844.54 239.728 844.932 240.901C845.324 242.073 845.461 243.316 845.334 244.546L829.022 425.676C828.727 428.289 827.485 430.704 825.531 432.466C823.578 434.227 821.048 435.213 818.418 435.237Z"
                        fill="url(#paint1_linear)" />
                    <path
                        d="M808.891 630.406H681.006C680.063 630.425 679.126 630.24 678.26 629.866C677.394 629.492 676.619 628.936 675.986 628.236C675.354 627.536 674.879 626.709 674.594 625.809C674.308 624.91 674.22 623.96 674.333 623.024L689.861 461.488C690.085 459.485 691.029 457.632 692.518 456.274C694.007 454.916 695.938 454.146 697.953 454.106H825.839C826.782 454.087 827.718 454.271 828.584 454.646C829.45 455.02 830.225 455.576 830.858 456.276C831.491 456.976 831.966 457.803 832.251 458.702C832.536 459.602 832.625 460.552 832.511 461.488L816.983 623.024C816.759 625.026 815.815 626.879 814.326 628.237C812.838 629.595 810.906 630.366 808.891 630.406Z"
                        fill="url(#paint2_linear)" />
                    <path
                        d="M778.403 541.294C792.826 527.549 794.395 505.785 781.909 492.685C769.422 479.584 747.609 480.107 733.187 493.852C718.765 507.598 717.195 529.361 729.682 542.462C742.168 555.563 763.981 555.04 778.403 541.294Z"
                        stroke="#EF6A77" strokeWidth="11.6898" strokeMiterlimit="10" />
                    <path
                        d="M739.583 549.348C727.619 544.182 720.036 531.901 721.413 517.573C723.238 498.584 740.111 483.191 759.1 483.191"
                        stroke="white" strokeWidth="11.6898" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M736.744 578.904H763.054" stroke="white" strokeWidth="5.98374" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M733.879 608.701H760.19" stroke="white" strokeWidth="5.98374" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path opacity="0.4" d="M721.296 593.634H775.67" stroke="white" strokeWidth="5.98374"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path
                        d="M652.495 630.406H524.61C523.667 630.425 522.73 630.24 521.864 629.866C520.999 629.492 520.223 628.936 519.59 628.236C518.958 627.536 518.483 626.709 518.198 625.809C517.912 624.91 517.824 623.96 517.937 623.024L533.465 461.488C533.689 459.485 534.633 457.632 536.122 456.274C537.611 454.916 539.542 454.146 541.557 454.106H669.443C670.386 454.087 671.322 454.271 672.188 454.646C673.054 455.02 673.829 455.576 674.462 456.276C675.095 456.976 675.57 457.803 675.855 458.702C676.14 459.602 676.229 460.552 676.115 461.488L660.587 623.024C660.363 625.026 659.419 626.879 657.93 628.237C656.442 629.595 654.51 630.366 652.495 630.406Z"
                        fill="#FEBB94" />
                    <path
                        d="M622.007 541.294C636.429 527.549 637.998 505.785 625.512 492.685C613.026 479.584 591.212 480.107 576.79 493.852C562.368 507.598 560.799 529.361 573.285 542.462C585.771 555.563 607.585 555.04 622.007 541.294Z"
                        stroke="#EF6A77" strokeWidth="11.6898" strokeMiterlimit="10" />
                    <path
                        d="M633.781 517.573C631.956 536.562 615.083 551.955 596.094 551.955C577.105 551.955 563.191 536.562 565.017 517.573C566.842 498.584 583.715 483.191 602.704 483.191"
                        stroke="white" strokeWidth="11.6898" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M580.348 578.904H606.658" stroke="white" strokeWidth="5.98374" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M577.483 608.701H603.794" stroke="white" strokeWidth="5.98374" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path opacity="0.4" d="M564.9 593.634H619.274" stroke="white" strokeWidth="5.98374"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path
                        d="M798.171 745.944H513.117C512.223 745.961 511.336 745.787 510.516 745.432C509.695 745.077 508.96 744.55 508.36 743.887C507.761 743.224 507.311 742.44 507.04 741.587C506.77 740.735 506.686 739.835 506.793 738.947L515.3 650.461C515.512 648.563 516.407 646.806 517.818 645.519C519.229 644.232 521.059 643.502 522.969 643.464H808.022C808.916 643.446 809.803 643.621 810.624 643.976C811.444 644.331 812.179 644.857 812.779 645.521C813.378 646.184 813.828 646.968 814.099 647.82C814.369 648.673 814.453 649.573 814.346 650.461L805.84 738.947C805.627 740.845 804.733 742.601 803.322 743.888C801.911 745.176 800.08 745.906 798.171 745.944Z"
                        fill="url(#paint3_linear)" />
                    <path d="M532.968 725.427L536.989 683.595" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M562.473 696.123L559.656 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M588.199 706.131L586.344 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M618.098 672.724L613.032 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M641.575 706.131L639.72 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M671.251 675.052L666.408 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M695.107 704.511L693.096 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M720.855 714.285L719.784 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M750.493 683.595L746.472 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M778.712 667.667L773.16 725.427" stroke="white" strokeWidth="11.6898"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path
                        d="M540.355 407.462L556.375 432.557C557.098 433.69 557.344 435.062 557.06 436.375C556.775 437.689 555.984 438.836 554.857 439.569L552.268 441.251C547.934 444.067 542.725 445.214 537.609 444.479C532.493 443.743 527.818 441.175 524.453 437.251L503.194 412.46L540.355 407.462Z"
                        fill="#FFCCB3" />
                    <path
                        d="M528.131 417.119C528.131 417.119 534.063 437.487 545.98 444.021C549.074 442.823 552.047 441.332 554.857 439.569L541.494 417.119"
                        fill="#FCBA9F" />
                    <path
                        d="M254.064 508.715L316.989 478.56C322.918 475.719 327.931 471.271 331.459 465.723L392.247 370.099C396.031 364.146 401.52 359.469 407.999 356.678L495.552 318.958L476.808 460.707L476.478 465.943C476.478 465.943 453.321 525.919 396.563 547.708L305.818 603.211L254.064 508.715Z"
                        fill="#FFCCB3" />
                    <path
                        d="M322.543 475.233C322.543 475.233 352.309 549.529 396.563 547.708L368.308 564.99C368.308 564.99 272.405 547.2 308.304 482.575L322.543 475.233Z"
                        fill="#FCBA9F" />
                    <path d="M482 345.191C482 345.191 451.044 363.949 452.686 407.462L489.861 393.57L482 345.191Z"
                        fill="#FCBA9F" />
                    <path
                        d="M559.764 407.462L575.784 432.557C576.507 433.69 576.753 435.062 576.469 436.375C576.185 437.689 575.393 438.836 574.266 439.569L571.677 441.251C567.343 444.067 562.134 445.214 557.018 444.479C551.902 443.743 547.227 441.175 543.862 437.251L522.604 412.46L559.764 407.462Z"
                        fill="#FFCCB3" />
                    <path
                        d="M796.596 417.358L494.025 417.089C492.789 417.122 491.56 416.892 490.421 416.412C489.281 415.933 488.257 415.216 487.416 414.31C486.575 413.403 485.938 412.328 485.546 411.155C485.154 409.982 485.017 408.74 485.144 407.51L501.456 226.38C501.752 223.766 502.993 221.351 504.947 219.59C506.9 217.829 509.43 216.843 512.061 216.818L814.631 217.088C815.867 217.054 817.096 217.285 818.236 217.764C819.375 218.243 820.4 218.96 821.24 219.866C822.081 220.773 822.718 221.849 823.11 223.021C823.502 224.194 823.639 225.437 823.513 226.666L807.201 407.796C806.905 410.41 805.663 412.825 803.71 414.586C801.756 416.347 799.226 417.333 796.596 417.358Z"
                         className="ele_themeBg_color" />
                    <path
                        d="M514.058 324.275C514.058 324.275 541.218 318.782 548.528 331.527C555.837 344.271 562.14 368.205 581.588 365.12C601.036 362.034 615.923 299.46 635.042 300.031C654.162 300.602 647.421 341.584 668.232 342.156C689.042 342.728 685.341 319.264 699.211 319.918C716.036 320.711 709.764 369.331 731.702 369.904C753.64 370.477 757.22 305.682 790.384 306.819"
                        stroke="white" strokeWidth="7.56507" strokeMiterlimit="10" strokeLinecap="round" />
                    <path d="M529.506 247.154L582.739 247.201" stroke="white" strokeWidth="11.9991"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path opacity="0.4" d="M527.308 271.556L637.318 271.654" stroke="white" strokeWidth="11.9991"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path
                        d="M424.307 416.46L463.121 399.363L502.814 372.268C513.253 365.143 535.053 361.933 542.901 371.839C550.884 381.915 541.917 401.847 532.235 410.302L495.552 442.335L476.477 465.943L424.307 416.46Z"
                        fill="#FFCCB3" />
                    <path
                        d="M503.194 382.557C503.194 382.557 508.434 402.763 522.02 406.259L536.43 394.085C537.891 392.997 539.115 391.623 540.028 390.047C540.941 388.47 541.523 386.724 541.739 384.916C541.955 383.107 541.801 381.273 541.285 379.526C540.769 377.779 539.903 376.155 538.739 374.754V374.754C536.706 372.306 533.87 370.66 530.736 370.11C527.602 369.561 524.374 370.143 521.63 371.753L503.194 382.557Z"
                        stroke="#FF967C" strokeWidth="3" strokeLinecap="round" />
                    <path
                        d="M319.38 476.062C319.38 476.062 311.858 529.857 377.316 560.66C377.316 560.66 330.294 603.794 312.165 611.047C294.037 618.299 225.924 544.182 250.15 502.114C256.944 490.317 319.38 476.062 319.38 476.062Z"
                         className="ele_themeBg_color" />
                    <path
                        d="M567.401 419.425C567.401 419.425 541.888 419.945 533.007 424.593L526.597 417.118L565.929 417.119L567.401 419.425Z"
                        fill="#FCBA9F" />
                    <path
                        d="M1037.16 417.358H855.755C842.09 417.358 831.993 406.28 833.203 392.615L857.542 117.742C858.752 104.078 870.81 93 884.475 93H1065.88C1079.55 93 1089.64 104.078 1088.43 117.742L1064.09 392.615C1062.88 406.28 1050.82 417.358 1037.16 417.358Z"
                        fill="#FEBB94" />
                    <path
                        d="M1011.19 248.209C1039.92 220.728 1042.81 177.137 1017.66 150.846C992.507 124.555 948.831 125.519 920.106 153C891.382 180.481 888.486 224.072 913.639 250.363C938.792 276.655 982.469 275.69 1011.19 248.209Z"
                        fill="white" />
                    <path
                        d="M969.493 202.744L1015.03 244.276C1015.03 244.276 1025.39 233.141 1028.48 225.183L969.493 202.744Z"
                        fill="url(#paint4_linear)" />
                    <path
                        d="M972.525 122.963L966.054 196.04C965.91 197.371 966.159 198.715 966.77 199.907C967.381 201.098 968.328 202.084 969.493 202.744L1031.28 237.142C1031.28 237.142 1067.97 134.783 972.525 122.963Z"
                         className="ele_themeBg_color" />
                    <path d="M875.814 320.069H950.966" stroke="white" strokeWidth="12" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M870.313 382.201H945.465" stroke="white" strokeWidth="12" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path opacity="0.4" d="M873.095 350.784H1028.4" stroke="white" strokeWidth="12"
                        strokeMiterlimit="10" strokeLinecap="round" />
                    <path
                        d="M814.065 619.855C814.065 619.855 838.29 613.394 851.183 627.277C851.183 627.277 874.357 623.069 883.779 635.961C883.779 635.961 904.324 637.151 910.274 645.531C915.222 646.214 919.901 648.195 923.834 651.274C927.767 654.352 930.815 658.418 932.666 663.057C938.284 676.298 933.519 752.849 927.935 780.28C926.539 787.141 927.017 794.251 929.32 800.863L943.524 841.682L845.247 882.765L821.915 821.909C821.915 821.909 793.514 776.268 789.456 768.153C785.399 760.039 792.276 692.706 778.425 650.643L814.065 619.855Z"
                        fill="#FFCCB3" />
                    <path
                        d="M834.591 854.971L831.756 847.576C831.756 847.576 882.751 844.316 929.32 800.863L933.774 813.663C933.774 813.663 928.022 840.103 901.834 852.564C875.645 865.026 834.591 854.971 834.591 854.971Z"
                        fill="#FCBA9F" />
                    <path
                        d="M834.591 854.971C834.591 854.971 896.318 860.593 933.774 813.663C933.774 813.663 947.603 820.913 968.838 873.994C975.36 890.298 866.451 948.345 848.842 924.216C848.842 924.216 833.64 892.059 834.591 854.971Z"
                        className="ele_themeBg_color" />
                    <path
                        d="M784.465 676.035C784.465 676.035 782.336 687.331 772.269 687.332C765.701 687.332 737.232 678.731 718.23 672.751C713.06 671.124 707.514 671.124 702.344 672.751C697.174 674.378 692.628 677.554 689.322 681.848L688.167 683.348C687.593 684.091 687.177 684.943 686.942 685.853C686.707 686.762 686.66 687.709 686.802 688.637C686.944 689.565 687.273 690.455 687.769 691.252C688.265 692.05 688.918 692.738 689.688 693.275C705.775 704.506 749.216 734.988 754.772 740.544C761.61 747.381 799.685 785.713 799.685 785.713L784.465 676.035Z"
                        fill="#FFCCB3" />
                    <path
                        d="M782.154 664.717C782.154 664.717 802.731 692.569 799.685 698.666C796.639 704.763 764.732 686.122 764.732 686.122C764.732 686.122 790.479 692.745 782.154 664.717Z"
                        fill="#FCBA9F" />
                    <path
                        d="M778.425 650.643L743.8 545.495C742.613 541.888 742.161 538.079 742.473 534.294C742.785 530.51 743.854 526.826 745.616 523.463C747.378 520.099 749.798 517.123 752.731 514.712C755.665 512.301 759.053 510.503 762.694 509.426C765.675 508.545 768.874 508.783 771.691 510.097C774.508 511.411 776.747 513.709 777.986 516.559L826.561 628.218L778.425 650.643Z"
                        fill="#FFCCB3" />
                    <path
                        d="M763.41 605.045C764.391 606.657 765.695 608.049 767.239 609.133C768.784 610.218 770.535 610.971 772.385 611.347"
                        stroke="#FF967C" strokeWidth="3" strokeLinecap="round" />
                    <path
                        d="M775.868 551.217C780.081 549.35 784.728 546.741 786.727 543.696L773.834 513.083C773.558 512.423 773.145 511.83 772.622 511.342C772.099 510.854 771.479 510.483 770.802 510.253C770.125 510.023 769.407 509.94 768.695 510.009C767.983 510.078 767.294 510.297 766.674 510.652C762.086 513.3 756.03 518.078 755.795 524.938L764.515 546.638C764.943 547.698 765.58 548.66 766.388 549.468C767.196 550.276 768.159 550.913 769.218 551.34C770.278 551.768 771.413 551.977 772.556 551.956C773.698 551.935 774.825 551.683 775.868 551.217Z"
                        stroke="#FF967C" strokeWidth="3" strokeLinecap="round" />
                    <path d="M822.654 619.236L834.591 645.531" stroke="#FF967C" strokeWidth="3"
                        strokeLinecap="round" />
                    <path d="M851.66 627.815C857.726 636.511 860.977 646.86 860.974 657.464" stroke="#FF967C"
                        strokeWidth="3" strokeLinecap="round" />
                    <path d="M884.349 636.453C884.349 636.453 890.358 650.234 890.854 661.132" stroke="#FF967C"
                        strokeWidth="3" strokeLinecap="round" />
                    <path d="M911.115 646.349C913.482 652.164 914.4 658.468 913.789 664.717" stroke="#FF967C"
                        strokeWidth="3" strokeLinecap="round" />
                    <path
                        d="M715.113 706.131C715.113 706.131 722.223 697.101 719.784 692.572C717.345 688.043 706.496 681.638 700.226 682.335C697.234 682.786 694.347 683.769 691.703 685.239C691.222 685.49 690.816 685.863 690.524 686.321C690.232 686.778 690.064 687.303 690.037 687.845C690.011 688.387 690.126 688.927 690.372 689.41C690.619 689.894 690.987 690.305 691.441 690.602L715.113 706.131Z"
                        stroke="#FF967C" strokeWidth="3" strokeLinecap="round" />
                    <path
                        d="M592.842 816.234H376.227C375.333 816.251 374.446 816.077 373.625 815.722C372.804 815.367 372.069 814.84 371.47 814.177C370.87 813.514 370.42 812.729 370.15 811.877C369.879 811.025 369.795 810.125 369.903 809.237L378.409 720.75C378.621 718.852 379.516 717.096 380.927 715.809C382.338 714.522 384.168 713.792 386.078 713.754H602.694C603.588 713.736 604.475 713.911 605.295 714.266C606.116 714.62 606.851 715.147 607.451 715.81C608.05 716.474 608.5 717.258 608.771 718.11C609.041 718.963 609.125 719.863 609.018 720.75L600.512 809.237C600.299 811.135 599.404 812.891 597.993 814.178C596.583 815.465 594.752 816.196 592.842 816.234Z"
                        fill="#FEBB94" />
                    <path d="M396.563 736.83H530.246" stroke="#EF6A77" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M396.563 736.83H489.46" stroke="white" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M394.563 764.994H528.246" stroke="#EF6A77" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M394.563 764.994H450.103" stroke="white" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M390.192 793.158H523.875" stroke="#EF6A77" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path d="M390.192 793.158H424.157" stroke="white" strokeWidth="11.6898" strokeMiterlimit="10"
                        strokeLinecap="round" />
                    <path
                        d="M577.567 744.817C581.979 744.817 585.555 741.241 585.555 736.83C585.555 732.418 581.979 728.842 577.567 728.842C573.156 728.842 569.58 732.418 569.58 736.83C569.58 741.241 573.156 744.817 577.567 744.817Z"
                        fill="white" />
                    <path
                        d="M574.977 771.749C579.389 771.749 582.965 768.173 582.965 763.762C582.965 759.35 579.389 755.774 574.977 755.774C570.565 755.774 566.989 759.35 566.989 763.762C566.989 768.173 570.565 771.749 574.977 771.749Z"
                        fill="white" />
                    <path
                        d="M572.086 801.146C576.497 801.146 580.074 797.569 580.074 793.158C580.074 788.746 576.497 785.17 572.086 785.17C567.674 785.17 564.098 788.746 564.098 793.158C564.098 797.569 567.674 801.146 572.086 801.146Z"
                        fill="white" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear" x1="634.203" y1="-7.66352" x2="918.04" y2="1414.72"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#94B9FF" />
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="667.724" y1="234.221" x2="695.245" y2="662.637"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#94B9FF" />
                    </linearGradient>
                    <linearGradient id="paint2_linear" x1="749.482" y1="453.685" x2="794.499" y2="826.442"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#94B9FF" />
                    </linearGradient>
                    <linearGradient id="paint3_linear" x1="652.911" y1="643.219" x2="660.84" y2="862.771"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#94B9FF" />
                    </linearGradient>
                    <linearGradient id="paint4_linear" x1="997.516" y1="202.645" x2="1004.28" y2="291.221"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="#94B9FF" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect width="843.53" height="837.007" fill="white" transform="translate(245 93)" />
                    </clipPath>
                </defs>
            </svg>

        </div>
        <div className='content_wrap'>
            <h2>Lorem ipsum dolor sit</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
        </div>

    </div>
    )
}

export default LeftWidget
